export default (theme) => ({
  brandPage: {
    padding: "50px 0",
    position: "relative",
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      padding: "70px 0",
      marginBottom: 110,
    },
    "& .background": {
      display: "block",
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("lg")]: {
        backgroundSize: "100vw auto",
      },
    },
    "& .brand-logo": {
      maxWidth: 315,
      marginBottom: "50px !important",
    },
    "& .intro-columns": {
      [theme.breakpoints.up("md")]: {
        width: "calc(100% + 24px)",
        margin: "0 -12px",
        maxWidth: "calc(100% + 24px)",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      },
      "& .intro": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 37.5%",
          maxWidth: "37.5%",
          padding: "0 12px",
        },
        "& h2": {
          color: theme.palette.primary.contrastText,
          fontSize: 20,
          lineHeight: 2.4,
          fontWeight: "700",
          letterSpacing: "0.008em",
          marginBottom: 32,
          marginTop: 0,
          [theme.breakpoints.up("md")]: {
            fontSize: 32,
            lineHeight: 1.5,
            marginBottom: 48,
          },
        },
        "& p": {
          color: theme.palette.primary.contrastText,
          fontSize: 16,
          letterSpacing: "0.032em",
          marginTop: 0,
          lineHeight: 1.5,
          marginBottom: 16,
        },
        "& .button": {
          fontSize: 18,
          fontWeight: 700,
          color: theme.palette.primary.contrastText,
          border: "1px solid " + theme.palette.primary.contrastText,
          borderRadius: 22,
          padding: "10px 32px",
          textDecoration: "none",
          margin: "32px 0",
          display: "table",
          transition: "color .3s ease-in-out, background-color .3s ease-in-out",
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
          },
        },
      },
      "& .facts": {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 43.75%",
          maxWidth: "43.75%",
          padding: "0 12px",
        },
        "&::before": {
          content: '"Did you know?"',
          fontSize: 10,
          letterSpacing: "0.152em",
          lineHeight: 1.6,
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          borderRadius: 20,
          padding: "12px 24px",
          display: "block",
          position: "absolute",
          left: 32,
          top: -20,
          zIndex: 2,
          textTransform: "uppercase",
          [theme.breakpoints.up("md")]: {
            left: 55,
          },
        },
        "& .fact-block": {
          backgroundColor: theme.palette.primary.contrastText,
          borderRadius: 8,
          marginBottom: 16,
          padding: 32,
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.up("md")]: {
            padding: 55,
            display: "flex",
          },
          "& h3": {
            fontSize: 20,
            lineHeight: 2.4,
            fontWeight: "700",
            letterSpacing: "0.008em",
            marginBottom: 6,
            marginTop: 0,
            [theme.breakpoints.up("md")]: {
              fontSize: 32,
              lineHeight: 1.5,
            },
          },
          "& p": {
            fontSize: 16,
            letterSpacing: "0.032em",
            marginTop: 0,
            lineHeight: 1.5,
            marginBottom: 0,
          },
          "& .image": {
            flex: "0 0 80px",
            maxWidth: 80,
            marginLeft: 12,
          },
        },
      },
    },
    "& .product-range": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: "calc(100% + 24px)",
      margin: "72px -12px 0",
      [theme.breakpoints.up("md")]: {
        margin: "150px -12px 0",
      },
      "& h2": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        flex: "0 0 100%",
        padding: "0 12px",
        maxWidth: "100%",
        color: theme.palette.primary.contrastText,
        fontSize: 24,
        lineHeight: 1,
        fontWeight: "500",
        marginBottom: 24,
        [theme.breakpoints.up("md")]: {
          flex: "0 0 87.5%",
          maxWidht: "87.5%",
          marginBottom: 40,
        },
        "&::after": {
          flex: "1 0 auto",
          marginLeft: 24,
          content: '""',
          display: "block",
          height: 1,
          backgroundColor: theme.palette.primary.main + "4D",
        },
      },
      "& .cat-grid": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 87.5%",
          maxWidth: "87.5%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 75%",
          maxWidth: "75%",
        },
        "& a.cat-link": {
          flex: "0 0 calc(50% - 24px)",
          backgroundColor: theme.palette.primary.contrastText,
          boxShadow: "0 6px 14px rgba(0,0,0,0.16)",
          padding: "0 14px 24px",
          textDecoration: "none",
          fontSize: 18,
          letterSpacing: "0.008em",
          lineHeight: 1.33,
          fontWeight: "bold",
          color: theme.palette.primary.main,
          margin: "0 12px 24px",
          transition: "opacity .3s ease-in-out",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 calc(25% - 24px)",
          },
          "&:hover, &:focus": {
            opacity: 0.9,
          },
          "& .gatsby-image-wrapper": {
            margin: "12px 0",
          },
        },
      },
    },
    "& .callout": {
      position: "relative",
      "& .gatsby-image-wrapper": {
        marginLeft: "50%",
        width: "100vw",
        transform: "translateX(-50%)",
        minHeight: 595,
      },
      "& p": {
        color: theme.palette.primary.contrastText,
        letterSpacing: "-0.008em",
        lineHeight: 1.25,
        margin: "0 auto",
        maxWidth: "80%",
        width: 690,
        fontStyle: "italic",
        fontWeight: 800,
        fontSize: 32,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        [theme.breakpoints.up("md")]: {
          fontSize: 52,
        },
        "& span": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .latest-products": {
      "& h3": {
        marginBottom: 24,
        [theme.breakpoints.up("md")]: {
          marginBottom: 40,
        },
      },
      "& .MuiGrid-grid-lg-4": {
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 25% !important",
          maxWidth: "25% !important",
        },
      },
      "& .button": {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
        border: "1px solid " + theme.palette.primary.contrastText,
        borderRadius: 22,
        padding: "10px 32px",
        textDecoration: "none",
        margin: "32px auto",
        display: "table",
        transition: "color .3s ease-in-out, background-color .3s ease-in-out",
        [theme.breakpoints.up("md")]: {
          marginBottom: 90,
          marginTop: 50,
        },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
        },
      },
    },
  },
});
